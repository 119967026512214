import React from 'react';
import Layout from '../components/layout';
// import Content from '../components/content'
import '../components/layout.css';
import ContentAllExceptIndex from '../components/content-all-except-index';
import SEO from '../components/seo';
import Footer from '../components/footer';
import GlobalCTA from '../components/globalcta';
import ContentFth from '../components/content-fth';
import ContentThd from '../components/content-thd';
import ContentSnd from '../components/content-snd';
// import {ctc_brand} from '../components/data'
// import Segment from '../images/cortex-global-segment.svg';
import CNXhome from '../images/CNX Home 01 Trans.png';

const Brands = () => {
	return (
		<Layout>
			<SEO title='Cortex - Brands' />
			<ContentAllExceptIndex title='branch'>
				<div className='cortex-home-content-nd'>
					Brands
					<div className='cortex-all-content-except-index-p'>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ullamcorper lectus turpis, sit
							amet vestibulum tellus scelerisque nec.
						</p>
					</div>
				</div>
			</ContentAllExceptIndex>
			<ContentThd>
				<div id='cortex-brands-content'>
					<div className='cortex-brands-content-snd-p'>
						<img src={CNXhome} style={{ width: 200 }} alt='ctcBrand' />
						<div className='cortex-brand-content-title'> Connexion for home </div>
						<p className='cortex-all-content-except-index-p'>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ullamcorperlectus turpis, sit
							amet vestibulum tellus scelerisque nec.
						</p>
					</div>
				</div>
				<div className='brands1'>
					<div style={{ float: 'left', width: '40%', height: 500 }}>
						<div style={{ float: 'right' }}>
							<div
								className='cortex-brands-content-mission'
								style={{ borderBottom: 'solid 1px #e0e0e0', paddingBottom: -10 }}
							>
								<p style={{ fontWeight: 'bold', marginTop: 10, fontSize: 24 }}>Mission</p>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ullamcorper lectus
									turpis, sit amet vestibulum tellus scelerisque nec.
								</p>
							</div>
							<div>
								<div className='cortex-brands-content-mission'>
									<p style={{ fontWeight: 'bold', marginTop: 10, fontSize: 24 }}>Vision</p>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ullamcorper lectus
										turpis, sit amet vestibulum tellus scelerisque nec.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className='cortex-brands-content-snd-all' />
				</div>
			</ContentThd>
			<ContentSnd>
				<div id='cortex-brands-content'>
					<div className='cortex-brands-content-snd-p'>
						<img src={CNXhome} style={{ width: 200 }} alt='ctcBrand' />
						<div className='cortex-brand-content-title'> Connexion for biz </div>
						<p className='cortex-all-content-except-index-p'>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ullamcorperlectus turpis, sit
							amet vestibulum tellus scelerisque nec.
						</p>
					</div>
				</div>
				<div className='brands1'>
					<div style={{ float: 'left', width: '40%', height: 500 }}>
						<div style={{ float: 'right' }}>
							<div
								className='cortex-brands-content-mission'
								style={{ borderBottom: 'solid 1px #e0e0e0', paddingBottom: -10 }}
							>
								<p style={{ fontWeight: 'bold', marginTop: 10, fontSize: 24 }}>Mission</p>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ullamcorper lectus
									turpis, sit amet vestibulum tellus scelerisque nec.
								</p>
							</div>
							<div>
								<div className='cortex-brands-content-mission'>
									<p style={{ fontWeight: 'bold', marginTop: 10, fontSize: 24 }}>Vision</p>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ullamcorper lectus
										turpis, sit amet vestibulum tellus scelerisque nec.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className='cortex-brands-content-snd-all' />
				</div>
			</ContentSnd>
			<ContentThd>
				<div id='cortex-about-content'>
					<div id='cortex-about-content-mission' style={{ textAlign: 'center' }}>
						{/* <img src={Segment} alt="ctcSegment"/> */}
						<p className='cortex-about-content-p'>Teko</p>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ullamcorper lectus turpis, sit
							amet vestibulum tellus scelerisque nec.
						</p>
						<div className='cortex-home-content-button' style={{ margin: '0 auto' }}>
							View info
						</div>
					</div>
					<div id='cortex-about-content-vision' style={{ textAlign: 'center' }}>
						{/* <img src={Segment} alt="ctcSegment"/> */}
						<p className='cortex-about-content-p'>Buddee</p>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ullamcorper lectus turpis, sit
							amet vestibulum tellus scelerisque nec.
						</p>
						<div className='cortex-home-content-button' style={{ margin: '0 auto' }}>
							View info
						</div>
					</div>
				</div>
			</ContentThd>
			<ContentThd>
				<div className='brands1'>
					<div className='cortex-brands-content-snd-all-left' />
					<div className='cortex-brands-content-snd-all-right' />
				</div>
			</ContentThd>
			<ContentFth>
				<GlobalCTA />
			</ContentFth>
			<Footer />
		</Layout>
	);
};

export default Brands;

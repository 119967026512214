import React from 'react';
import '../components/layout.css';

const ContentThd = ({ children }) => {
	return (
		<React.Fragment>
			<div id='cortex-content-all-thd'>{children}</div>
		</React.Fragment>
	);
};

export default ContentThd;

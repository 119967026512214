import React from 'react';
import { Link } from 'gatsby';

const GlobalCTA = () => {
    const inlineStyle={
        globalCTAp:{
            fontWeight: 'normal', fontSize: 16 
        },
        globalTextDecNone:{
            textDecoration:"none"
        },
        globalButton:{
            margin: '0 auto' 
        }
    }
	return (
		<React.Fragment>
			<div id='cortex-home-content-global-cta-img' alt='Cortex-cta'>
				<div className='cortex-home-content-global-cta-p'>
					<p className='cortex-home-content-global-cta-p-gl'>
						Contact Cortex
						<p style={inlineStyle.globalCTAp}>
							Think Big, Speak up and Let's Get It Done - we encourage you to reach out to us and ask.
						</p>
					</p>
					<Link to='/contacts' style={inlineStyle.globalTextDecNone}>
						<div id='cortex-home-content-snd-all-brand-button' style={inlineStyle.globalButton}>
							Contact Us
						</div>
					</Link>
				</div>
			</div>
		</React.Fragment>
    );
    
};

export default GlobalCTA;

import React from 'react';
import './layout.css';

const ContentFth = ({ children }) => {
	return (
		<React.Fragment>
			<div id='cortex-content-all-fth'>{children}</div>
		</React.Fragment>
	);
};

export default ContentFth;
